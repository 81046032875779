<template>
  <div class="login-wrap">
    <div class="login-top">
      <div class="title-box">
        <div class="left_box">
          <img :src="logo" alt="">
          <span>{{ cName }}</span>
        </div>
        <div class="right_box">
          已有账号，<span @click="tologin">马上登录</span>
        </div>

      </div>
    </div>
    <div class="login-content" :style="bigBc">
      <div class="login-Mainbox">
        <div class="right-box">
          <el-divider class="login-divider"> <span class="loginTitle">用户注册</span> </el-divider>
          <el-form :model="param" :rules="rules" ref="login" label-width="15px" class="ms-content" size='medium' @keyup.enter.native="handleSubmit">
            <el-form-item prop="isPerson">
              <el-radio-group v-model="param.isPerson" @input="errorChange">
                <el-radio :label="false">企业用户</el-radio>
                <el-radio :label="true">个人用户</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="name" label=" ">
              <el-tooltip effect="dark" :content="param.isPerson?'输入个人名称':'输入企业名称'" placement="right">
                <el-input v-model="param.name" :placeholder="param.isPerson?'输入个人名称':'输入企业名称'" @input="errorChange" />
              </el-tooltip>
            </el-form-item>
            
            <el-form-item prop="username" label=" ">
              <el-tooltip effect="dark" content="输入用户名" placement="right">
                  <el-input v-model="param.username" placeholder="输入用户名" @input="errorChange" />
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="password" label=" ">
              <el-tooltip effect="dark" content="输入密码" placement="right">
                <el-input @input="errorChange" type="password" placeholder="输入密码" v-model="param.password" />
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="confirmPwd" label=" ">
              <el-tooltip effect="dark" content="输入密码" placement="right">
                <el-input @input="errorChange" type="password"  placeholder="确认密码" v-model="param.confirmPwd" />
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="regionId" label=" ">
              <el-cascader :options="areaList" style="width: 100%"  v-model="param.regionId" @input="errorChange" :props="{value:'id',label:'name'}" :show-all-levels="false" prop="regionId" placeholder="选择所在区域" clearable></el-cascader>
            </el-form-item>
            <el-form-item prop="isAgress" label=" " class="userAgress_box">
                <el-checkbox v-model="param.isAgress">
                  我已接受<span class="agress_box" @click.stop="agressClick('GetUserProtocol')">用户协议</span>和<span class="agress_box" @click.stop="agressClick('GetUserPrivacy')">隐私政策</span>
                </el-checkbox>
            </el-form-item>
            <div class="login-btn">
              <el-button type="primary" :loading="isLoad" @click="handleSubmit" class="btn">注册</el-button>
            </div>
            <p class="login-tips" v-show="errorState">{{ errortext }}</p>
          </el-form>
        </div>
      </div>
    </div>
    <div class="footbox">
      <div class="info-box">
        <span> {{ support }}</span>
        <span>{{ companyName }} </span>
      </div>
      <p>{{ copyright }} <a href="https://beian.miit.gov.cn" target="_blank" style="color:#000000">蜀ICP备15018571号-3</a></p>
    </div>
    <el-drawer title="用户协议和隐私政策" :visible.sync="drawer" direction="rtl" size='100%'>
      <div v-html="agressContent" class="drawer_content_box">
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { hnRule } from "@/libs/validate";
import { imgAddHost, checkImgExists } from "@/libs/util";
import md5 from "js-md5";
export default {
  data() {
    let confirmPwd = (val) => {
      if (val === this.param.password) {
        return { result: true, errMsg: "确认密码不一致" };
      } else {
        return { result: false, errMsg: "确认密码不一致" };
      }
    };
    return {
      isdisabled: false,
      param: {
        companyId: "",
        regionId: "",
        username: "",
        password: "",
        confirmPwd: "",
        name: "",
        isPerson: false,
        isAgress: false,
      },
      rules: {
        username: hnRule(true, "userName", "请输入用户名"),
        password: hnRule(true, "Password", "请输入密码"),
        confirmPwd: hnRule(true, confirmPwd, "请输入确认密码"),
        name: hnRule(true, "length", "请输入名称"),
        isAgress: hnRule(true, "", "请勾选用户协议"),
        
      },
      areaList: [],
      errorState: false,
      errortext: "",
      isLoad: false,
      companyName: "后台管理系统",
      verifyUrl: "",
      copyright: "",
      logo: "",
      bigBc: "",
      boxBc: "",
      support: "",
      cName: "",
      slogan: "",
      agressContent: "",
      drawer: false,
    };
  },
  created() {
    this.SelectData();

    this.getWebSiteCfg().then((res) => {
      if (res) {
        this.copyright = res.copyright;
        this.logo = imgAddHost(res.logo);
        this.handleImg(res.loginBgImg, "bigBc");
        this.handleImg(res.loginBoxBgImg, "boxBc");
        this.slogan = res.slogan || "打造企业专属的物联网云平台";
        this.support = res.support || "";
        this.companyName = res.companyName;
        this.$set(this.param, "companyId", res.id);
        this.cName = res.cName;
        this.support = res.support != "" ? "技术支持:" + res.support : "";
        this.buildUnit =
          res.buildUnit == "" || res.buildUnit == null
            ? ""
            : "建设单位:" + res.buildUnit;
      }
    });
  },
  methods: {
    ...mapActions(["handleLogin", "getUserInfo", "getWebSiteCfg"]),
    errorChange() {
      if (this.errorState) this.errorState = !this.errorState;
    },
    // 登录验证
    handleSubmit() {
      this.$refs.login.validate((valid) => {
        if (valid) {
          if (!this.param.isAgress) {
            this.$message({
              message: "请勾选用户协议和隐私政策",
              type: "warning",
            });
            return;
          }
          this.isLoad = true;
          this.isLoad = true;
          let password = md5(this.param.password) || "";
          let confirmPwd = md5(this.param.confirmPwd) || "";
          const regionLength = this.param.regionId.length;
          let regionId = regionLength
            ? this.param.regionId[regionLength - 1]
            : "";
          let handleData = JSON.parse(JSON.stringify(this.param));
          let data = Object.assign(handleData, {
            password,
            confirmPwd,
            regionId,
          });
          this.$api
            .EntRegister(data)
            .then((ele) => {
              this.isLoad = false;
              if (ele.errcode === 0) {
                this.$message({
                  message: "恭喜你，注册成功",
                  type: "success",
                });
                this.$set(this.param, "password", "");
                this.$set(this.param, "name", "");
                this.$set(this.param, "confirmPwd", "");
                this.isLoad = false;
              } else {
                this.isLoad = false; //允许点击注册按钮
                this.errorState = true;
                this.errortext = ele.errmsg;
              }
            })
            .catch((err) => {
              this.isLoad = false;
            });
        } else {
          return false;
        }
      });
    },
    //  处理图片
    handleImg(imgUrl, type) {
      const hostUrL = imgAddHost(imgUrl);
      checkImgExists(hostUrL)
        .then((data) => {
          if (type === "bigBc") {
            this.bigBc = { backgroundImage: "url(" + hostUrL + ")" };
          } else if (type === "boxBc") {
            this.boxBc = { backgroundImage: "url(" + hostUrL + ")" };
          }
        })
        .catch((err) => {
          if (type === "bigBc") {
            this.bigBc = {
              backgroundImage: "url(@/assets/imgs/login/bg.png)",
            };
          } else if (type === "boxBc") {
            this.boxBc = {
              backgroundImage: "url(@/assets/imgs/login/decorate.png)",
            };
          }
        });
    },
    async SelectData() {
      const res = await this.$api.SysRegionList();
      if (res) this.areaList = res || [];
    },
    async agressClick(key) {
      if (key) {
        const res = await this.$api[key]();
        this.isAgress = false;
        this.drawer = true;
        this.agressContent = res.content;
      }
    },
    tologin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-wrap {
  width: 100vw;
  height: 100vh;
  background-size: 100%;
  overflow-y: auto;
  .login-top {
    width: 100%;
    height: 9vh;
    background: #fff;
    .title-box {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left_box {
        font-size: 28px;
        color: #343434;
        display: flex;
        align-items: center;
        img {
          width: 45px;
          margin-right: 10px;
        }
      }
      .right_box {
        & > span {
          color: #07c3a9;
          cursor: pointer;
        }
      }
    }
  }
  .login-content {
    height: 620px;
    width: 100%;
    //  background: url('../../assets/imgs/login/bg.png');
    //  flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .login-Mainbox {
      width: 550px;
      height: 550px;
      border-radius: 12px;
      background: #fff;
      // padding: 10px;
      box-sizing: border-box;
      display: flex;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      .left-box {
        width: 55%;
        height: 100%;
        // background: cadetblue;
        border-radius: 12px 0 0 12px;
        // background: url('../../assets/imgs/login/decorate.png') no-repeat;
        background-repeat: no-repeat;
        background-size: 503px 328px;
        background-position: 0% 100%;
        // position: relative;
        .left-tilte-box {
          // display: flex;
          // align-items: center;
          // justify-content: c;
          text-align: center;
          padding: 20px 0;
        }
        .left-tilte-box > p:first-child {
          font-size: 32px;
          color: #07c3a9;
          padding: 4px 0;
        }
        .left-tilte-box > p:last-child {
          font-size: 22px;
          //  color: #07C3A9;
        }
      }
      .right-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 30px 80px;
        border-left: 1px solid #f6f6f6;
        .ms-content{
           width: 100%;
        }
        .login-divider {
          background-color: #07c3a9;
          //  margin-bottom: 10px;
          .loginTitle {
            font-size: 22px;
            color: #07c3a9;
            font-weight: bold;
          }
        }
      }
    }
  }
  .footbox {
    width: 100%;
    // flex: 1;
    // background: rgba(54,54,54,0.6);
    text-align: center;
    // color: #fff;
    // text-align: center;
    // line-height: 30px;
    font-size: 12px;
    min-height: 80px;
    padding: 20px;
    box-sizing: border-box;
    .info-box {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .info-box > span {
      cursor: pointer;
    }
    .info-box > span:last-child {
      margin-left: 10px;
      padding-left: 10px;
      // border-left: 1px solid #686262;
    }
  }
  .userAgress_box{
     margin-bottom: 0px;
     height: 24px;
  }
  .userAgress_box ::v-deep .el-form-item__content{
     height: 24px;
      line-height: 24px;
  }
  .userAgress_box ::v-deep .el-form-item__error{
     top: 1px;
     left: 230px;
  }
  .userAgress_box ::v-deep .el-form-item__label{
    height: 32px;
    line-height: 32px;
  }
}

.agress_box {
  color: #f40000;
  cursor: pointer;
}
.drawer_content_box {
  box-sizing: border-box;
  padding: 0 20px;
}
.ms-content {
  padding: 20px 10px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: 2px;
  // margin-top: 5px;
  background: #07c3a9;
}
.agress_info_box {
  margin-top: 20px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: red;
}
.verifyBox {
  display: flex;
  align-items: center;
}
.verifyInp {
  flex: 1;
}
.verifyUrl {
  width: 75px;
  height: 32px;
  margin-left: 20px;
  flex-shrink: 0;
}
@media screen and (max-width: 1450px) {
  .login-wrap {
    .login-top {
      .title-box {
        width: 1100px;
        font-size: 20px;
        img {
          width: 32px;
          margin-right: 5px;
        }
      }
    }
    .login-content {
      height: 520px;
      .login-Mainbox {
        width: 500px;
        height: 480px;
        .right-box {
          padding: 15px 80px;
        }
        .el-form-item {
          margin-bottom: 15px;
        }
      }
    }
  }
  .ms-content {
    padding: 10px 10px;
  }
  .login-btn button {
    // margin-top: 10px;
    margin-top: 5px;
  }
}
</style>